<template>
  <v-container>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form class="p-2" @submit.prevent="submit">
        <validation-provider v-slot="{ errors }" name="Mission" rules="required">
          <v-text-field
            v-model="emergencies.mission_id"
            :error-messages="errors"
            label="Mission"
            required
            outlined
            dense
          >
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Emergency Item" rules="required">
          <v-text-field
            v-model="emergencies.emergency_item_id"
            :error-messages="errors"
            label="Emergency Item"
            required
            outlined
            dense
          >
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Name" rules="required">
          <v-text-field v-model="emergencies.name" :error-messages="errors" label="Name" required outlined dense>
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Number" rules="required">
          <v-text-field v-model="emergencies.number" :error-messages="errors" label="Number" required outlined dense>
          </v-text-field>
        </validation-provider>

        <v-btn type="submit" color="primary" class="ma-10 pa-5" :disabled="invalid">
          {{ "SAVE" }}
        </v-btn>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import util from "@/core/utils/misc";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "EmergencyAdd",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return { emergencies: {} };
  },
  methods: {
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        await Request.post(`${this.$IYA.URI.EMERGENCIES__ADD}`, this.emergencies).then(response => {
          if (response.status === this.$IYA.HTTP.OK) {
            this.$swal(`Successful`, `Action approved`, `success`);
          }
        });
        util.isLoading();
      }
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
